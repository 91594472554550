import React from "react";
import { useEffect, useState } from "react";
import img1 from "../../assets/Carport/carport-1100x300.webp";
import img2 from "../../assets/Marketstal/marketstal-1100x300.webp";
import img3 from "../../assets/Profile/profile-ocynkowane-1100x300.webp";
import css from "./MobileTopBar.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const MobileTopBar = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    { img: img1, link: "https://carport.ulamex.com.pl/" },
    { img: img2, link: "https://marketstal.pl/" },
    { img: img3, link: "https://profileocynkowane.com.pl/" },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 60000);
    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <div className={css.bannerContainer}>
      <a href={images[currentIndex].link} className={css.bannerImage}>
        <LazyLoadImage
          src={images[currentIndex].img}
          alt={`Slide ${currentIndex}`}
          className="aspect-auto"
          effect="blur"
          width={"100%"}
          height={"100%"}
        />
      </a>
    </div>
  );
};
export default MobileTopBar;

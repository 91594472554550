import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import { onKeyDown } from "../../utils/onKeyDownFunction";
import { onChangeInput } from "../../utils/onChangeInput.ts";
import {
  validateRoundRodDiameter,
  validateRoundRodLength,
  validateRoundRodQuantity,
} from "../../utils/validateEachType";
import messages from "../../errorTypes/Types.ts";

const PretOkraglyAlu = ({ density, onWeightChange }) => {
  const [diameter, setDiameter] = useState("");
  const [length, setLength] = useState("");
  const [count, setCount] = useState(1);

  const [errorDiameter, setErrorDiameter] = useState(null);
  const [errorLength, setErrorLength] = useState(null);
  const [errorCount, setErrorCount] = useState(null);

  const calculateWeight = useCallback(() => {
    const radius = Number(diameter) / 2; // przeliczam średnicę na promień
    const volume =
      Math.PI * Math.pow(radius, 2) * Number(length) * 100 * density; // Masa
    const volumeKg = (volume / 100000) * count;
    const volumeMeter =
      ((Math.PI * Math.pow(radius, 2) * 1 * density) / 1000) * count;

    onWeightChange({
      totalWeight: volumeKg.toFixed(3),
      totalWeightPerKg: volumeMeter.toFixed(3),
    });
  }, [diameter, length, count, density, onWeightChange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 100ms

    return () => {
      clearTimeout(handler);
    };
  }, [calculateWeight]);

  return (
    <div className="obliczenia">
      <div className="obliczenia-inputs">
        <label htmlFor="diameter-input">[A] Wpisz średnicę pręta (mm)</label>
        <Input
          id="diameter-input"
          type="text"
          autoComplete="off"
          value={diameter}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setDiameter,
              validateRoundRodDiameter,
              3,
              2,
              setErrorDiameter,
              messages.pretyOk.diameter
            );
          }}
          onBlur={() => {
            if (!validateRoundRodDiameter(diameter)) {
              setErrorDiameter("Niepoprawny format długości [A]");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setDiameter)}
        />
        {errorDiameter && <p className="text-error-text">{errorDiameter}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="length-input">[B] Wpisz długość pręta (m)</label>
        <Input
          id="length-input"
          type="text"
          autoComplete="off"
          value={length}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setLength,
              validateRoundRodLength,
              2,
              2,
              setErrorLength,
              messages.pretyOk.rodLength
            );
          }}
          onBlur={() => {
            if (!validateRoundRodLength(length)) {
              setErrorLength("Niepoprawny format długości [B]");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setLength)}
        />
        {errorLength && <p className="text-error-text">{errorLength}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="count-input">Wpisz ilość (szt.)</label>
        <Input
          id="count-input"
          type="text"
          autoComplete="off"
          value={count}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setCount,
              validateRoundRodQuantity,
              5,
              0,
              setErrorCount,
              messages.pretyOk.quantity
            );
          }}
          onBlur={() => {
            if (!validateRoundRodQuantity(count)) {
              setErrorCount("Niepoprawny format ilości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setCount)}
        />
        {errorCount && <p className="text-error-text">{errorCount}</p>}
      </div>
    </div>
  );
};

export default PretOkraglyAlu;

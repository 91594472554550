import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import { onChangeInput } from "../../utils/onChangeInput.ts";
import {
  validateAngleHeight,
  validateAngleLength,
  validateAngleQuantity,
  validateAngleThickness,
  validateAngleWidth,
} from "../../utils/validateEachType";
import { onKeyDown } from "../../utils/onKeyDownFunction";
import messages from "../../errorTypes/Types.ts";

const Katowniki = ({ density, onWeightChange }) => {
  const [x, setX] = useState("");
  const [y, setY] = useState("");
  const [zBox, setZBox] = useState("");
  const [length, setLength] = useState("");
  const [count, setCount] = useState(1);

  const [errorX, setErrorX] = useState(null);
  const [errorY, setErrorY] = useState(null);
  const [errorZBox, setErrorZBox] = useState(null);
  const [errorLength, setErrorLength] = useState(null);
  const [errorCount, setErrorCount] = useState(null);

  const calculateWeight = useCallback(() => {
    const calculatedWeight =
      ((Number(x) * Number(zBox) + (Number(y) - Number(zBox)) * Number(zBox)) *
        density) /
      1000;
    const weight = calculatedWeight * count;
    const weightPerMeter = weight * Number(length) * count;

    onWeightChange({
      totalWeight: weight.toFixed(3),
      totalWeightPerKg: weightPerMeter.toFixed(3),
    });
  }, [x, y, zBox, length, count, density, onWeightChange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 100ms

    return () => {
      clearTimeout(handler);
    };
  }, [calculateWeight]);

  return (
    <div className="obliczenia">
      <div className="obliczenia-inputs">
        <label htmlFor="x-input">[A] Wpisz szerokość (mm)</label>
        <Input
          id="x-input"
          type="text"
          autoComplete="off"
          value={x}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setX,
              validateAngleWidth,
              4,
              2,
              setErrorX,
              messages.ceowniki.width
            );
          }}
          onBlur={() => {
            if (!validateAngleWidth(x)) {
              setErrorX("Niepoprawny format szerokości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setX)}
        />
        {errorX && <p className="text-error-text">{errorX}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="y-input">[B] Wpisz wysokość (mm)</label>
        <Input
          id="y-input"
          type="text"
          autoComplete="off"
          value={y}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setY,
              validateAngleHeight,
              5,
              2,
              setErrorY,
              messages.ceowniki.height
            );
          }}
          onBlur={() => {
            if (!validateAngleHeight(y)) {
              setErrorY("Niepoprawny format wysokości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setY)}
        />
        {errorY && <p className="text-error-text">{errorY}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="zBox-input">[C] Wpisz grubość ścianki (mm)</label>
        <Input
          id="zBox-input"
          type="text"
          autoComplete="off"
          value={zBox}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setZBox,
              validateAngleThickness,
              2,
              2,
              setErrorZBox,
              messages.ceowniki.thickness
            );
          }}
          onBlur={() => {
            if (!validateAngleThickness(zBox)) {
              setErrorZBox("Niepoprawny format grubości ścianki");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setZBox)}
        />
        {errorZBox && <p className="text-error-text">{errorZBox}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="length-input">[D] Wpisz długość kątownika (m)</label>
        <Input
          id="length-input"
          type="text"
          autoComplete="off"
          value={length}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setLength,
              validateAngleLength,
              3,
              2,
              setErrorLength,
              messages.ceowniki.length
            );
          }}
          onBlur={() => {
            if (!validateAngleLength(length)) {
              setErrorLength("Niepoprawny format długości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setLength)}
        />
        {errorLength && <p className="text-error-text">{errorLength}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="count-input">Wpisz ilość (szt.)</label>
        <Input
          id="count-input"
          type="text"
          autoComplete="off"
          value={count}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setCount,
              validateAngleQuantity,
              5,
              0,
              setErrorCount,
              messages.ceowniki.quantity
            );
          }}
          onBlur={() => {
            if (!validateAngleQuantity(count)) {
              setErrorCount("Niepoprawny format grubości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setCount)}
        />
        {errorCount && <p className="text-error-text">{errorCount}</p>}
      </div>
    </div>
  );
};

export default Katowniki;

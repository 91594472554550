const messages = {
  blachy: {
    width: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 4",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Szerokość nie może być pusta",
      invalid: "Niepoprawny format szerokości",
    },
    thickness: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 2",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Pole grubości blachy jest wymagane",
      invalid: "Niepoprawny format możliwej grubości",
    },
    length: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 5",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Długość blachy jest wymagana",
      invalid: "Niepoprawny format długości",
    },
    quantity: {
      maxInteger: "Max liczba cyfr: 6",
      maxDecimal: "Wartość bez części dziesiętnej",
      empty: "Ilość blachy jest wymagana",
      invalid: "Niepoprawny format wpisanej ilości",
    },
  },
  ceowniki: {
    width: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 4",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Szerokość nie może być pusta",
      invalid: "Niepoprawny format szerokości",
    },
    height: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 5",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Wysokość nie może być pusta",
      invalid: "Niepoprawny format wysokości",
    },
    thickness: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 2",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Pole grubości ceownika jest wymagane",
      invalid: "Niepoprawny format możliwej grubości",
    },
    length: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 3",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Długość ceownika jest wymagana",
      invalid: "Niepoprawny format długości",
    },
    quantity: {
      maxInteger: "Max liczba cyfr: 5",
      maxDecimal: "Wartość bez części dziesiętnej",
      empty: "Ilość jest wymagana",
      invalid: "Niepoprawny format wpisanej ilości",
    },
  },
  dwuteowniki: {
    length: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 2",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Długość dwuteownika jest wymagana",
      invalid: "Niepoprawny format długości",
    },
    quantity: {
      maxInteger: "Max liczba cyfr: 5",
      maxDecimal: "Wartość bez części dziesiętnej",
      empty: "Ilość jest wymagana",
      invalid: "Niepoprawny format wpisanej ilości",
    },
  },
  plaskowniki: {
    width: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 4",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Szerokość nie może być pusta",
      invalid: "Niepoprawny format szerokości",
    },
    thickness: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 2",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Pole grubości płaskownika jest wymagane",
      invalid: "Niepoprawny format możliwej grubości",
    },
    length: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 5",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Długość płaskownika jest wymagana",
      invalid: "Niepoprawny format długości",
    },
    quantity: {
      maxInteger: "Max liczba cyfr: 5",
      maxDecimal: "Wartość bez części dziesiętnej",
      empty: "Ilość jest wymagana",
      invalid: "Niepoprawny format wpisanej ilości",
    },
  },
  katowniki: {
    width: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 4",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Szerokość nie może być pusta",
      invalid: "Niepoprawny format szerokości",
    },
    height: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 5",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Wysokość nie może być pusta",
      invalid: "Niepoprawny format wysokości",
    },
    thickness: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 2",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Pole grubości ceownika jest wymagane",
      invalid: "Niepoprawny format możliwej grubości",
    },
    length: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 3",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Długość kątownika jest wymagana",
      invalid: "Niepoprawny format długości",
    },
    quantity: {
      maxInteger: "Max liczba cyfr: 5",
      maxDecimal: "Wartość bez części dziesiętnej",
      empty: "Ilość jest wymagana",
      invalid: "Niepoprawny format wpisanej ilości",
    },
  },
  pretyKw: {
    sideLength: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 5",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Długość jest wymagana",
      invalid: "Niepoprawny format długości",
    },
    rodLength: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 2",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Długość jest wymagana",
      invalid: "Niepoprawny format długości",
    },
    quantity: {
      maxInteger: "Max liczba cyfr: 5",
      maxDecimal: "Wartość bez części dziesiętnej",
      empty: "Ilość jest wymagana",
      invalid: "Niepoprawny format wpisanej ilości",
    },
  },
  pretyOk: {
    diameter: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 3",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Średnica jest wymagana",
      invalid: "Niepoprawny format średnicy",
    },
    rodLength: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 2",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Długość jest wymagana",
      invalid: "Niepoprawny format długości",
    },
    quantity: {
      maxInteger: "Max liczba cyfr: 5",
      maxDecimal: "Wartość bez części dziesiętnej",
      empty: "Ilość jest wymagana",
      invalid: "Niepoprawny format wpisanej ilości",
    },
  },
  rura: {
    diameter: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 4",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Średnica jest wymagana",
      invalid: "Niepoprawny format długości",
    },
    thickness: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 2",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Pole grubości rury jest wymagane",
      invalid: "Niepoprawny format możliwej grubości",
    },
    length: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 5",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Długość jest wymagana",
      invalid: "Niepoprawny format długości",
    },
    quantity: {
      maxInteger: "Max liczba cyfr: 5",
      maxDecimal: "Wartość bez części dziesiętnej",
      empty: "Ilość jest wymagana",
      invalid: "Niepoprawny format wpisanej ilości",
    },
  },
  rownoramienne: {
    width: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 4",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Szerokość nie może być pusta",
      invalid: "Niepoprawny format szerokości",
    },
    thickness: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 2",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Pole grubości jest wymagane",
      invalid: "Niepoprawny format możliwej grubości",
    },
    length: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 5",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Długość kątownika jest wymagana",
      invalid: "Niepoprawny format długości",
    },
    quantity: {
      maxInteger: "Max liczba cyfr: 5",
      maxDecimal: "Wartość bez części dziesiętnej",
      empty: "Ilość jest wymagana",
      invalid: "Niepoprawny format wpisanej ilości",
    },
  },
  profilZamKw: {
    width: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 4",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Szerokość nie może być pusta",
      invalid: "Niepoprawny format szerokości",
    },
    thickness: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 2",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Pole grubości profilu jest wymagane",
      invalid: "Niepoprawny format możliwej grubości",
    },
    length: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 2",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Długość profilu jest wymagana",
      invalid: "Niepoprawny format długości",
    },
    quantity: {
      maxInteger: "Max liczba cyfr: 5",
      maxDecimal: "Wartość bez części dziesiętnej",
      empty: "Ilość jest wymagana",
      invalid: "Niepoprawny format wpisanej ilości",
    },
  },
  profilZamPr: {
    width: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 4",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Szerokość nie może być pusta",
      invalid: "Niepoprawny format szerokości",
    },
    height: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 5",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Wysokość nie może być pusta",
      invalid: "Niepoprawny format wysokości",
    },
    thickness: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 2",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Pole grubości profilu jest wymagane",
      invalid: "Niepoprawny format możliwej grubości",
    },
    length: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 3",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Długość profilu jest wymagana",
      invalid: "Niepoprawny format długości",
    },
    quantity: {
      maxInteger: "Max liczba cyfr: 5",
      maxDecimal: "Wartość bez części dziesiętnej",
      empty: "Ilość jest wymagana",
      invalid: "Niepoprawny format wpisanej ilości",
    },
  },
  pretySz: {
    diagonal: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 4",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Przekątna jest wymagana",
      invalid: "Niepoprawny format przekątnej",
    },
    length: {
      maxInteger: "Max liczba cyfr przed przecinkiem: 5",
      maxDecimal: "Max liczba cyfr po przecinku: 2",
      empty: "Długość jest wymagana",
      invalid: "Niepoprawny format długości",
    },
    quantity: {
      maxInteger: "Max liczba cyfr: 5",
      maxDecimal: "Wartość bez części dziesiętnej",
      empty: "Ilość jest wymagana",
      invalid: "Niepoprawny format wpisanej ilości",
    },
  },
};

export default messages;

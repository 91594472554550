import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import { onChangeInput } from "../../utils/onChangeInput.ts";
import { onKeyDown } from "../../utils/onKeyDownFunction";
import {
  validateHexagonalRodDiagonal,
  validateHexagonalRodLength,
  validateHexagonalRodQuantity,
} from "../../utils/validateEachType";
import messages from "../../errorTypes/Types.ts";

const PretSzescio = ({ density, onWeightChange }) => {
  const [sideLength, setSideLength] = useState("");
  const [length, setLength] = useState("");
  const [count, setCount] = useState(1);

  const [errorSideLength, setErrorSideLength] = useState(null);
  const [errorLength, setErrorLength] = useState(null);
  const [errorCount, setErrorCount] = useState(null);

  const calculateWeight = useCallback(() => {
    const sanitizedSideLength = sanitize(sideLength);
    // Obliczanie powierzchni sześciokątnego pręta // 5mm , 1000mm, 1szt,2.7 g/cm3
    const surfaceArea =
      (sanitizedSideLength * sanitizedSideLength * 0.8660311) / 10; //2.16507775

    // Obliczanie objętości pręta
    const volume = surfaceArea * Number(length); // 2.16507775 * 1000 = 2165.07775

    // Obliczanie masy pręta
    const weight = ((volume * density) / 100) * count; //( 2165.07775 * 2.7) / 100 * 1 = 58.45709925
    const weightPerMeter = ((surfaceArea * 100 * density) / 10000) * count; // ((2.16507775*100*2,7)/10000)*1 = 0.05845709925

    onWeightChange({
      totalWeight: weight.toFixed(3),
      totalWeightPerKg: weightPerMeter.toFixed(3),
    });
  }, [sideLength, length, count, density, onWeightChange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 100ms

    return () => {
      clearTimeout(handler);
    };
  }, [calculateWeight]);

  // Funkcja do czyszczenia danych
  const sanitize = (value) => {
    // Implementuj swoją funkcję sanitize, np. parsowanie wartości do liczby
    return parseFloat(value);
  };

  return (
    <div className="obliczenia">
      <div className="obliczenia-inputs">
        <label htmlFor="sideLenght-input">[A] Wpisz przekątną pręta (mm)</label>
        <Input
          id="sideLenght-input"
          type="text"
          autoComplete="off"
          value={sideLength}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setSideLength,
              validateHexagonalRodDiagonal,
              4,
              2,
              setErrorSideLength,
              messages.pretySz.diagonal
            );
          }}
          onBlur={() => {
            if (!validateHexagonalRodDiagonal(sideLength)) {
              setErrorSideLength("Niepoprawny format przekątnej");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setSideLength)}
        />
        {errorSideLength && (
          <p className="text-error-text">{errorSideLength}</p>
        )}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="length-input">[B] Wpisz długość pręta (mm)</label>
        <Input
          id="length-input"
          type="text"
          autoComplete="off"
          value={length}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setLength,
              validateHexagonalRodLength,
              5,
              2,
              setErrorLength,
              messages.pretySz.length
            );
          }}
          onBlur={() => {
            if (!validateHexagonalRodLength(length)) {
              setErrorLength("Niepoprawny format długości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setLength)}
        />
        {errorLength && <p className="text-error-text">{errorLength}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="count-input">Wpisz ilość (szt.)</label>
        <Input
          id="count-input"
          type="text"
          autoComplete="off"
          value={count}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setCount,
              validateHexagonalRodQuantity,
              5,
              0,
              setErrorCount,
              messages.pretySz.quantity
            );
          }}
          onBlur={() => {
            if (!validateHexagonalRodQuantity(count)) {
              setErrorCount("Niepoprawny format ilości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setCount)}
        />
        {errorCount && <p className="text-error-text">{errorCount}</p>}
      </div>
    </div>
  );
};

export default PretSzescio;

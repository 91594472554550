import React from "react";
import css from "./ButtomBar.module.css";
import { Button } from "primereact/button";

const ButtomBar = () => {
  return (
    <div className={css.container}>
      <a
        href="https://kalkulatorstali.com/blog"
        target="_blank"
        rel="noreferrer"
        className={css.buttomBarButton}
      >
        Zobacz nasz blog
      </a>
    </div>
  );
};

export default ButtomBar;

// Blacha
export const validateSheetWidth = (text) => {
  const widthRegex = /^[1-9]\d{0,3}(\.\d{0,2})?$/;
  return widthRegex.test(text);
};

export const validateSheetThickness = (text) => {
  const thicknessRegex = /^[1-9]\d{0,2}(\.\d{0,2})?$/;
  return thicknessRegex.test(text);
};

export const validateSheetLength = (text) => {
  const lengthRegex = /^[1-9]\d{0,4}(\.\d{0,2})?$/;
  return lengthRegex.test(text);
};

export const validateSheetQuantity = (text) => {
  const quantityRegex = /^[1-9]\d{0,5}$/;
  return quantityRegex.test(text);
};
// -------------------------------------------
// C-Profil
export const validateCShapeWidth = (text) => {
  const widthRegex = /^[1-9]\d{0,3}(\.\d{0,2})?$/;
  return widthRegex.test(text);
};

export const validateCShapeHeight = (text) => {
  const heightRegex = /^[1-9]\d{0,4}(\.\d{0,2})?$/;
  return heightRegex.test(text);
};

export const validateCShapeThickness = (text) => {
  const thicknessRegex = /^[1-9]\d{0,2}(\.\d{0,2})?$/;
  return thicknessRegex.test(text);
};

export const validateCShapeLength = (text) => {
  const lengthRegex = /^(0|[1-9]\d{0,3})(\.\d{0,2})?$/;
  return lengthRegex.test(text);
};

export const validateCShapeQuantity = (text) => {
  const quantityRegex = /^[1-9]\d{0,5}$/;
  return quantityRegex.test(text);
};
// -------------------------------------------
// Dwuteowniki
export const validateDoubleTShapeLength = (text) => {
  const lengthRegex = /^(0|[1-9]\d{0,2})(\.\d{0,2})?$/;
  return lengthRegex.test(text);
};

export const validateDoubleTShapeQuantity = (text) => {
  const quantityRegex = /^[1-9]\d{0,5}$/;
  return quantityRegex.test(text);
};
// -------------------------------------------

// Katowniki

export const validateAngleWidth = (text) => {
  const widthRegex = /^[1-9]\d{0,3}(\.\d{0,2})?$/;
  return widthRegex.test(text);
};

export const validateAngleHeight = (text) => {
  const heightRegex = /^[1-9]\d{0,4}(\.\d{0,2})?$/;
  return heightRegex.test(text);
};

export const validateAngleThickness = (text) => {
  const thicknessRegex = /^[1-9]\d{0,2}(\.\d{0,2})?$/;
  return thicknessRegex.test(text);
};

export const validateAngleLength = (text) => {
  const lengthRegex = /^(0|[1-9]\d{0,3})(\.\d{0,2})?$/;
  return lengthRegex.test(text);
};

export const validateAngleQuantity = (text) => {
  const quantityRegex = /^[1-9]\d{0,5}$/;
  return quantityRegex.test(text);
};

// -------------------------------------------

// Plaskowniki

export const validateFlatBarWidth = (text) => {
  const widthRegex = /^[1-9]\d{0,3}(\.\d{0,2})?$/;
  return widthRegex.test(text);
};

export const validateFlatBarThickness = (text) => {
  const thicknessRegex = /^[1-9]\d{0,2}(\.\d{0,2})?$/;
  return thicknessRegex.test(text);
};

export const validateFlatBarLength = (text) => {
  const lengthRegex = /^[1-9]\d{0,5}(\.\d{0,2})?$/;
  return lengthRegex.test(text);
};

export const validateFlatBarQuantity = (text) => {
  const quantityRegex = /^[1-9]\d{0,5}$/;
  return quantityRegex.test(text);
};

// -------------------------------------------
// Prety Kw

export const validateSquareRodSideLength = (text) => {
  const lengthRegex = /^[1-9]\d{0,4}(\.\d{0,2})?$/;
  return lengthRegex.test(text);
};

export const validateSquareRodLength = (text) => {
  const lengthRegex = /^(0|[1-9]\d{0,2})(\.\d{0,2})?$/;
  return lengthRegex.test(text);
};

export const validateSquareRodQuantity = (text) => {
  const quantityRegex = /^[1-9]\d{0,5}$/;
  return quantityRegex.test(text);
};

// -------------------------------------------
// Prety Ok

export const validateRoundRodDiameter = (text) => {
  const diameterRegex = /^[1-9]\d{0,3}(\.\d{0,2})?$/;
  return diameterRegex.test(text);
};

export const validateRoundRodLength = (text) => {
  const lengthRegex = /^(0|[1-9]\d{0,2})(\.\d{0,2})?$/;
  return lengthRegex.test(text);
};

export const validateRoundRodQuantity = (text) => {
  const quantityRegex = /^[1-9]\d{0,5}$/;
  return quantityRegex.test(text);
};

// -------------------------------------------
// Rura

export const validatePipeDiameter = (text) => {
  const diameterRegex = /^[1-9]\d{0,3}(\.\d{0,2})?$/;
  return diameterRegex.test(text);
};

export const validatePipeThickness = (text) => {
  const thicknessRegex = /^[1-9]\d{0,2}(\.\d{0,2})?$/;
  return thicknessRegex.test(text);
};

export const validatePipeLength = (text) => {
  const lengthRegex = /^[1-9]\d{0,4}(\.\d{0,2})?$/;
  return lengthRegex.test(text);
};

export const validatePipeQuantity = (text) => {
  const quantityRegex = /^[1-9]\d{0,5}$/;
  return quantityRegex.test(text);
};

// -------------------------------------------

// Katowniki rownoramienne

export const validateAngleEqualWidth = (text) => {
  const widthRegex = /^[1-9]\d{0,3}(\.\d{0,2})?$/;
  return widthRegex.test(text);
};

export const validateAngleEqualThickness = (text) => {
  const thicknessRegex = /^[1-9]\d{0,2}(\.\d{0,2})?$/;
  return thicknessRegex.test(text);
};

export const validateAngleEqualLength = (text) => {
  const lengthRegex = /^[1-9]\d{0,4}(\.\d{0,2})?$/;
  return lengthRegex.test(text);
};

export const validateAngleEqualQuantity = (text) => {
  const quantityRegex = /^[1-9]\d{0,5}$/;
  return quantityRegex.test(text);
};

// -------------------------------------------
// Profile zamknięte kwadratowe

export const validateSquareProfileWidth = (text) => {
  const widthRegex = /^[1-9]\d{0,3}(\.\d{0,2})?$/;
  return widthRegex.test(text);
};

export const validateSquareProfileThickness = (text) => {
  const thicknessRegex = /^[1-9]\d{0,2}(\.\d{0,2})?$/;
  return thicknessRegex.test(text);
};

export const validateSquareProfileLength = (text) => {
  const lengthRegex = /^(0|[1-9]\d{0,2})(\.\d{0,2})?$/;
  return lengthRegex.test(text);
};

export const validateSquareProfileQuantity = (text) => {
  const quantityRegex = /^[1-9]\d{0,5}$/;
  return quantityRegex.test(text);
};

// -------------------------------------------
// Profile zamknięte prostokątne

export const validateRectangularProfileWidth = (text) => {
  const widthRegex = /^[1-9]\d{0,3}(\.\d{0,2})?$/;
  return widthRegex.test(text);
};

export const validateRectangularProfileHeight = (text) => {
  const heightRegex = /^[1-9]\d{0,4}(\.\d{0,2})?$/;
  return heightRegex.test(text);
};

export const validateRectangularProfileThickness = (text) => {
  const thicknessRegex = /^[1-9]\d{0,2}(\.\d{0,2})?$/;
  return thicknessRegex.test(text);
};

export const validateRectangularProfileLength = (text) => {
  const lengthRegex = /^(0|[1-9]\d{0,3})(\.\d{0,2})?$/;
  return lengthRegex.test(text);
};

export const validateRectangularProfileQuantity = (text) => {
  const quantityRegex = /^[1-9]\d{0,5}$/;
  return quantityRegex.test(text);
};

// -------------------------------------------
// Pręty sześciokątne

export const validateHexagonalRodDiagonal = (text) => {
  const diagonalRegex = /^[1-9]\d{0,3}(\.\d{0,2})?$/;
  return diagonalRegex.test(text);
};

export const validateHexagonalRodLength = (text) => {
  const lengthRegex = /^[1-9]\d{0,4}(\.\d{0,2})?$/;
  return lengthRegex.test(text);
};

export const validateHexagonalRodQuantity = (text) => {
  const quantityRegex = /^[1-9]\d{0,5}$/;
  return quantityRegex.test(text);
};

// -------------------------------------------

// export const validateInput = (text, type) => {
//   let regex;
//   switch (type) {
//     case "width":
//       regex = /^[1-9]\d{0,5}(\.\d{0,2})?$/;
//       break;
//     case "height":
//       regex = /^[1-9]\d{0,5}(\.\d{0,2})?$/;
//       break;
//     case "thickness":
//       regex = /^[1-9]\d{0,2}(\.\d{0,3})?$/;
//       break;
//     case "length":
//       regex = /^[1-9]\d{0,5}(\.\d{0,2})?$/;
//       break;
//     case "quantity":
//       regex = /^[1-9]\d{0,5}$/;
//       break;
//     default:
//       return false;
//   }
//   return regex.test(text);
// };

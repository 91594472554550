import React, { useState } from "react";
import css from "./AluList.module.css";
import AluItemDetail from "../../components/AluItemDetail/AluItemDetail";

const AluList = ({ items, activeComponent, density, setDensity }) => {
  const [selectedItem, setSelectedItem] = useState(items[0]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [weights, setWeights] = useState({
    totalWeight: 0.0,
    totalWeightPerKg: 0.0,
  });

  const handleWeightChange = (newWeights) => {
    setWeights(newWeights);
  };

  return (
    <>
      <div className={css.buttonGroupsContainer}>
        <div className={css.buttonGroups}>
          {items.map((item) => (
            <span
              key={item.id}
              className={`${css.li} ${
                selectedItem === item ? css.selected : ""
              }`}
            >
              <div
                onClick={() => setSelectedItem(item)}
                onMouseOver={() => setHoveredItem(item)}
                onMouseOut={() => setHoveredItem(null)}
                className={`${css.link}`}
              >
                <img
                  src={
                    selectedItem === item || hoveredItem === item
                      ? item.image2
                      : item.image
                  }
                  alt={item.name}
                  width={"40%"}
                  height={"100%"}
                />
              </div>
            </span>
          ))}
        </div>
      </div>
      <div className="details">
        {selectedItem && (
          <AluItemDetail
            item={selectedItem}
            activeComponent={activeComponent}
            density={density}
            setDensity={setDensity}
            onWeightChange={handleWeightChange}
          />
        )}
      </div>
      <div className="info">
        <p>
          * Każdy wynik to masa przypuszczalna - wynik nie uwzględnia tolerancji
          wykonania.
        </p>
      </div>
      <div className="p-2 mb-[10%] h-[150px]">
        {weights.totalBlacha ? (
          <div className="pweight">
            <p>Masa arkusza:</p>
            <span className="redWeight">
              {weights.totalBlacha}
              <span className="weight">kg/arkusz</span>
            </span>
          </div>
        ) : (
          ""
        )}
        {weights.totalWeight ? (
          <div className="pweight">
            <p>Waga całkowita:</p>
            <span className="redWeight">
              {isNaN(weights.totalWeight) ? "0.000" : weights.totalWeight}
              <span className="weight">kg</span>
            </span>
          </div>
        ) : (
          ""
        )}
        {weights.totalWeightPerKg ? (
          <div className="pweight">
            <p>Waga na metr:</p>
            <span className="redWeight">
              {isNaN(weights.totalWeightPerKg)
                ? "0.000"
                : weights.totalWeightPerKg}
              <span className="weight">kg/m</span>
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AluList;

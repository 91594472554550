import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import { onChangeInput } from "../../utils/onChangeInput.ts";
import { onKeyDown } from "../../utils/onKeyDownFunction";
import {
  validatePipeDiameter,
  validatePipeLength,
  validatePipeQuantity,
  validatePipeThickness,
} from "../../utils/validateEachType";
import messages from "../../errorTypes/Types.ts";

const Rura = ({ density, onWeightChange }) => {
  const [diameter, setDiameter] = useState("");
  const [thickness, setThickness] = useState("");
  const [length, setLength] = useState("");
  const [count, setCount] = useState(1);

  const [errorDiameter, setErrorDiameter] = useState(null);
  const [errorThickness, setErrorThickness] = useState(null);
  const [errorLength, setErrorLength] = useState(null);
  const [errorCount, setErrorCount] = useState(null);

  const calculateWeight = useCallback(() => {
    const outerRadius = Number(diameter) / 2; // cm
    const innerRadius = outerRadius - Number(thickness); // cm
    const volume =
      Math.PI *
      (Math.pow(outerRadius, 2) - Math.pow(innerRadius, 2)) *
      Number(length) *
      100; // cm^3
    const weight = ((volume * density) / 100000) * Number(count); // kg
    const weightPerMeter =
      ((Math.PI *
        (Math.pow(outerRadius, 2) - Math.pow(innerRadius, 2)) *
        100 *
        density) /
        100000) *
      count; // kg/m

    onWeightChange({
      totalWeight: weight.toFixed(3),
      totalWeightPerKg: weightPerMeter.toFixed(3),
    });
  }, [diameter, thickness, length, count, density, onWeightChange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 100ms

    return () => {
      clearTimeout(handler);
    };
  }, [calculateWeight]);

  return (
    <div className="obliczenia">
      <div className="obliczenia-inputs">
        <label htmlFor="diameter-input">[A] Wpisz średnicę (mm)</label>
        <Input
          id="diameter-input"
          type="text"
          autoComplete="off"
          value={diameter}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setDiameter,
              validatePipeDiameter,
              4,
              2,
              setErrorDiameter,
              messages.rura.diameter
            );
          }}
          onBlur={() => {
            if (!validatePipeDiameter(diameter)) {
              setErrorDiameter("Niepoprawny format średnicy");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setDiameter)}
        />
        {errorDiameter && <p className="text-error-text">{errorDiameter}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="thickness-input">[B] Wpisz grubość ścianki (mm)</label>
        <Input
          id="thickness-input"
          type="text"
          autoComplete="off"
          value={thickness}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setThickness,
              validatePipeThickness,
              2,
              2,
              setErrorThickness,
              messages.rura.thickness
            );
          }}
          onBlur={() => {
            if (!validatePipeThickness(thickness)) {
              setErrorThickness("Niepoprawny format grubości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setThickness)}
        />
        {errorThickness && <p className="text-error-text">{errorThickness}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="length-input">[C] Wpisz długość rury (mm)</label>
        <Input
          id="length-input"
          type="text"
          autoComplete="off"
          value={length}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setLength,
              validatePipeLength,
              5,
              2,
              setErrorLength,
              messages.rura.length
            );
          }}
          onBlur={() => {
            if (!validatePipeLength(length)) {
              setErrorLength("Niepoprawny format długości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setLength)}
        />
        {errorLength && <p className="text-error-text">{errorLength}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="count-input">Wpisz ilość (szt.)</label>
        <Input
          id="count-input"
          type="text"
          autoComplete="off"
          value={count}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
          }}
          onChange={(e) => {
            onChangeInput(
              e,
              setCount,
              validatePipeQuantity,
              5,
              0,
              setErrorCount,
              messages.rura.quantity
            );
          }}
          onBlur={() => {
            if (!validatePipeQuantity(count)) {
              setErrorCount("Niepoprawny format ilości");
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setCount)}
        />
        {errorCount && <p className="text-error-text">{errorCount}</p>}
      </div>
    </div>
  );
};

export default Rura;
